/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "WiderCat 92: A New Era in Multihull Yachting"), "\n", React.createElement(_components.p, null, "The maritime industry witnessed a significant milestone with the launch of the second WiderCat 92, named Atali, at the Wider Vision Hub in Fano, Italy. This event marks a pivotal moment for Wider, a company renowned for its innovative approach to yacht design and construction. Atali, alongside its sister ship Acali, which was launched earlier in January, represents the cutting edge in multihull yacht design."), "\n", React.createElement(_components.h2, null, "Innovative Design and Propulsion"), "\n", React.createElement(_components.p, null, "The WiderCat 92, measuring an impressive 28 meters, is a testament to modern yacht engineering. Designed by the acclaimed Luca Dini Design, this multihull yacht incorporates a serial hybrid propulsion system. This innovative feature not only enhances the yacht's performance but also significantly reduces its environmental impact, setting a new standard in sustainable maritime design."), "\n", React.createElement(_components.h2, null, "Celebrating Craftsmanship and Innovation"), "\n", React.createElement(_components.p, null, "The launch of Atali was a moment of pride for the skilled craftspeople at the Wider Vision Hub shipyard in Fano, Italy. This event not only celebrates the completion of a remarkable vessel but also highlights the shipyard's commitment to excellence and innovation in yacht construction."), "\n", React.createElement(_components.p, null, "For more information on the WiderCat 92 and its launch, visit ", React.createElement(_components.a, {
    href: "https://www.yachtharbour.com/news/second-widercat-92-launched-at-wider-vision-hub-in-italy-7304?src=home_page_latest_list_pos_2"
  }, "Yacht Harbour"), "."), "\n", React.createElement(_components.p, null, "The introduction of Atali to the seas is more than just the unveiling of a new yacht; it is a glimpse into the future of multihull yachting, where design, performance, and sustainability converge to create unparalleled maritime experiences."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
